<template>
  <section />
</template>

<script>
export default {
  name: "RedirectPage",
  mounted() {
    const dest = this.$route.meta.to.name || "HomeIn";
    setTimeout(() => {
      this.$router.push({ name: dest }).catch(() => {});
    }, 500);
  }
};
</script>
